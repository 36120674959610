import Constants from 'Constants'
import { EventBus } from 'EventBus'
import Common from '@/assets/js/common.js'

const PaxMixin = {
    methods: {
        getCriteres: async function(lang = null) {
            let url = Constants.PAX_CRITERES + "?licence_key="+Constants.USER_LICENCE_KEY
            if(lang !== null) {
            	url += "&lang=" + lang
            }

			const result = await this.$request.request_get_api("PaxMixin::getCriteres", url)
			.catch(error => {
				console.error("PaxMixin::getCriteres => ERROR", error)
				return null
			})

			return result ? result.retour : null
        },

		saveCaracterisationByHorse: async function(horse_id, criteres_id, criteres_value) {
            const url =  this.constructRoute(Constants.PAX_CARACTERISATION_HORSE, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            const params = {criteres: [{criteres_id, criteres_value}]}

            const response = await this.$request.request_post_api("PaxMixin::saveCaracterisationByHorse", url, params, false)
            .catch(e => {
                return false
            })

            if(response.code_retour === 0){
                return true
            }
		},

		getCaracterisationByHorse: async function(horse_id) {
            const url =  this.constructRoute(Constants.PAX_CARACTERISATION_HORSE, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("PaxMixin::getCaracterisationByHorse", url)
			.catch(error => {
				console.error("PaxMixin::getCaracterisationByHorse => ERROR", error)
				return null
			})

			return result ? result.retour : null
		},

		saveStallionCountries: async function(horse_id, countries) {
            const url =  this.constructRoute(Constants.PAX_PAYS_DISPO_HORSE, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY

            const response = await this.$request.request_post_api("PaxMixin::saveStallionCountries", url, {countries}, false)
            .catch(e => {
                return false
            })

            if(response.code_retour === 0){
                return true
            }
		},

		getStallionCountries: async function(horse_id) {
            const url =  this.constructRoute(Constants.PAX_PAYS_DISPO_HORSE, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("PaxMixin::getStallionCountries", url)
			.catch(error => {
				console.error("PaxMixin::getStallionCountries => ERROR", error)
				return null
			})

			return result ? result.retour : null
		},

		saveCritereByLang: async function(criteres_id, lang, params) {
            const url =  this.constructRoute(Constants.PAX_CRITERES_BY_ID, { criteres_id }) +'?licence_key='+Constants.USER_LICENCE_KEY + "&lang=" + lang

            const response = await this.$request.request_post_api("PaxMixin::saveCritereByLang", url, params, false)
            .catch(e => {
                return false
            })

            if(response.code_retour === 0){
                return true
            }
		}
    }
}

export default PaxMixin